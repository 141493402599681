import React from "react";
import { clx } from "../utils/clx";

export default function Text({ as, onclick, weight, classname, children }) {
      const H1Classes = clx(
        "text-[32px] xl:text-[50px] font-[700] font-montserrat",
        classname
      );
      const H2Classes = clx(
        "text-[24px] xl:text-[30px] font-[700] font-montserrat",
        classname
      );
      const classes = clx(
            "text-[16px] leading-normal font-montserrat",
            classname
      )
      switch (as) {
        case "h1":
          return <h1 className={H1Classes} onClick={onclick}>{children}</h1>;
        case "h2":
          return <h2 className={H2Classes} onClick={onclick}>{children}</h2>;
        case "h3":
          return <h3 className={H1Classes} onClick={onclick}>{children}</h3>;
        case "h4":
          return <h4 className={H1Classes} onClick={onclick}>{children}</h4>;
        case "h5":
          return <h5 className={H1Classes} onClick={onclick}>{children}</h5>;
        case "h6":
          return <h6 className={H1Classes} onClick={onclick}>{children}</h6>;
        case "p":
          return <p className={classes} onClick={onclick}>{children}</p>;
        case "span":
          return <span className={classes} onClick={onclick}>{children}</span>;
        case "article":
                  return <article className={classes} onClick={onclick}>{children}</article>;
            default:
                  return <div onClick={onclick}>{children}</div>
      }
}