import React from 'react'
import { clx } from '../utils/clx'

export default function Header({
      classname,
      children
}) {
      const classes = clx(
            "bg-secondary w-full h-fit",
            classname
      )
      return (
        <div className={classes}>
          <div className='w-[90%] mx-auto py-4 flex justify-between items-center'>
            <div className='w-[81px] h-[48px]'>
              <img src='/images/logo.png' alt='' className='h-full w-full' />
            </div>
            {/* <div>
              <span className='block lg:inline text-[14px] lg:text-[18px] text-[#9AA0B1]'>
                Already have early access?{" "}
              </span>
              <span className='block lg:inline text-right lg:text-left text-primary font-semibold'>Log in</span>
            </div> */}
          </div>
        </div>
      );
}