import React from "react";
import Button from "../components/button";
import Footer from "../components/footer";
import Header from "../components/header";
import MailchimpEmailInput from "../components/mailchimp-form-container";
import Section from "../components/section";
import StepCard from "../components/step-card";
import Text from "../components/text";
import { tabs } from "../constants/tabs";
import HubspotForm from "./components/form";
import {MdCancel} from 'react-icons/md'
import Modal from "../components/modal";

export default function Homepage() {
  // const defaultImage=tabs
  const [activeTab, setActiveTab] = React.useState(1);
  const [activeImage, setActiveImage] = React.useState(tabs[0].img_route);
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isMainModalOpen, setMainModalOpen] = React.useState(false)

  // React.useEffect(() => {
  //   !activeImage && setActiveImage("/images/clients.png");
  // }, [activeImage]);

  return (
    <React.Fragment>
      <Header />
      <div className='bg-[#F4F7FF]'>
        <Section
          background='bg-primary w-full'
          classname='pt-10 relative h-fit'
        >
          <div className='h-[570px] md:h-[600px] lg:h-[600px] xl:h-[750px] py-[50px]'>
            <Text
              as='h1'
              classname='w-full lg:w-[60%] mx-auto text-center text-secondary'
            >
              Streamline the way you manage your FX business
            </Text>
            <Text
              as='p'
              classname='text-center py-6 text-[24px] lg:text-[18px] lg:py-14 text-[#E2E7F5]'
            >
              Get early access to this revolutionary front-to-back FX management
              solution
            </Text>
            <div className='block mt-[30px] lg:mt-[20px] w-fit mx-auto'>
              <Button
                variant='secondary'
                onclick={() => setMainModalOpen(true)}
              >
                Get early access
              </Button>
            </div>
            <Text
              as='span'
              classname='hidden text-[#E2E7F5] text-[12px] lg:text-[16px] text-center  py-6'
            >
              By requesting early access, You accept our Privacy Policy
            </Text>
          </div>

          <div className='lg:w-[76%] lg:h-[789px] mx-auto absolute left-0 right-0 bottom-[-170px] md:bottom-[-400px] lg:bottom-[-450px] xl:bottom-[-450px]'>
            <img
              src='/images/hero-img.png'
              alt='hero_image'
              className='w-full h-full object-contain'
            />
          </div>
        </Section>
        <Section background='mt-[200px] md:mt-[450px] lg:mt-[400px] xl:mt-[500px]'>
          <Text
            as='h1'
            classname='lg:w-[80%] text-center mx-auto text-[#1A2F67]'
          >
            A new way to track and manage your foreign exchange deals has
            arrived in Nigeria
          </Text>
          <div className='w-[50px] my-16 mx-auto'>
            <img src='/images/chevrons-down.png' alt='' />
          </div>
        </Section>
        <div className='w-[90%] lg:w-[95%] mx-auto lg:mt-[170px] relative'>
          <div className='rounded-3xl bg-[#DEE7FF] w-full p-6 flex flex-col lg:flex-row relative'>
            <div
              className={`absolute z-40 inset-0 top-0 bottom-0 lg:w-[45%] h-full bg-[#10214F]/90 ${
                isModalOpen
                  ? "ml-0 border-2 border-primary"
                  : "ml-[-100%] hidden lg:flex"
              } duration-300 rounded-3xl flex items-center justify-center lg:justify-start p-6`}
            >
              {/* Popup Modal  */}
              {/* <MailchimpEmailInput background='bg-primary' buttonVariant='secondary' classname='lg:w-full' /> */}
              <div className='relative w-full'>
                <HubspotForm
                  classname='w-full bg-transparent lg:w-full md:w-full px-0 lg:p-6'
                  onClose={() => setModalOpen(false)}
                  useErrorType2
                />
              </div>
            </div>
            {/* End of Popup Modal */}
            <div className='lg:w-[45%] relative'>
              <div className='grid grid-cols-2 md:grid-cols-3 gap-6 place-content-between gap-y-2'>
                {tabs.map((tab, index) => (
                  <Text
                    key={tab.id}
                    as='span'
                    classname={`font-bold cursor-pointer text-primary py-2 ${
                      activeTab === index + 1 && "border-b-2 font-extrabold"
                    } border-primary w-fit text-[18px]`}
                    onclick={() => {
                      setActiveTab(index + 1);
                      setActiveImage(tab.img_route);
                      setSelectedTab(tab);
                    }}
                  >
                    {tab.label}
                  </Text>
                ))}
              </div>
              <div>
                <Text
                  as='h2'
                  classname='text-[24px] font-bold text-[#1A2F67] lg:w-[85%] xl:w-[90%] py-5 pb-0'
                >
                  {selectedTab.title}
                </Text>
                <div className='lg:hidden'>
                  <div className='lg:absolute z-10 lg:top-[-150px] pt-[30px] lg:pt-0 right-0 lg:w-[54%] lg:h-[550px]'>
                    <img
                      src={activeImage}
                      alt=''
                      className='w-full h-full object-fill'
                    />
                  </div>
                </div>
                <ul className='pl-4 lg:pl-6 pt-6'>
                  {selectedTab.content.map((content) => (
                    <li
                      key={content}
                      className='text-primary w-[90%] lg:w-[75%] text-[14px] lg:text-[16px] list-disc'
                    >
                      {content}
                    </li>
                  ))}
                </ul>
                <Text
                  as='article'
                  classname='text-primary w-[90%] lg:w-[75%] text-[14px] lg:text-[18px] py-4'
                ></Text>
                <Button
                  variant='primary'
                  onclick={() => setModalOpen(true)}
                  classname='w-fit mt-5'
                >
                  Get early access
                </Button>
              </div>
            </div>

            <div className='flex-1 hidden lg:block'>
              <div className='lg:absolute lg:top-[-90px] xl:top-[-150px] pt-[30px] lg:pt-0 right-[20px] lg:w-[52%] xl:w-[54%] lg:h-[400px] xl:h-[550px]'>
                <img
                  src={activeImage}
                  alt=''
                  className='w-full h-full object-contain'
                />
              </div>
            </div>
          </div>
        </div>
        <Section classname='pt-[100px] lg:pt-[200px]'>
          <Text as='h1' classname='text-center text-primary'>
            How it works
          </Text>
          <Text
            as='article'
            classname='text-[14px] lg:text-[24px] py-6 lg:w-[509px] mx-auto text-center text-primary font-medium'
          >
            Set up you FXIQ account in four easy steps and start your automation
            now
          </Text>
          <div className='flex flex-row justify-center lg:flex-col lg:py-[100px] h-fit'>
            <div className='flex flex-col lg:flex-row items-center justify-evenly lg:justify-center gap-6'>
              <div>
                <img src='/images/account-icon.png' alt='' />
              </div>
              <div>
                <img
                  src='/images/connector.png'
                  alt=''
                  className='rotate-90 lg:rotate-0'
                />
              </div>
              <div>
                <img src='/images/client-icon.png' alt='' />
              </div>
              <div>
                <img
                  src='/images/connector.png'
                  alt=''
                  className='rotate-90 lg:rotate-0'
                />
              </div>
              <div>
                <img src='/images/bank-icon.png' alt='' />
              </div>
              <div>
                <img
                  src='/images/connector.png'
                  alt=''
                  className='rotate-90 lg:rotate-0'
                />
              </div>
              <div>
                <img src='/images/business-icon.png' alt='' />
              </div>
            </div>
            <div className='lg:my-16 flex flex-col lg:flex-row justify-between'>
              <StepCard step={1} title='Sign up'>
                Register a business or an individual account and choose your
                plan
              </StepCard>
              <StepCard step={2} title='Set up profiles'>
                Create your employee and client profiles
              </StepCard>
              <StepCard step={3} title='Set up your company(ies)'>
                Add all your internal companies and bank accounts
              </StepCard>
              <StepCard step={4} title='Add your transactions'>
                Capture your 1st transaction and start managing your FX deals
              </StepCard>
            </div>
          </div>
        </Section>
        <Section classname='py-[70px] lg:py-[150px]'>
          <div className='h-fit flex flex-col-reverse lg:flex-row rounded-3xl bg-white relative'>
            <div className='lg:w-[50%] ml-auto p-4 lg:p-6 py-12'>
              <Text as='h2' classname='text-primary font-bold text-[30px] pb-6'>
                What more are you waiting for, click below to{" "}
                <span className='text-[#4964AB]'>request your</span> early
                access today.
              </Text>
              {/* <Text
                as='article'
                classname='py-6 text-[18px] lg:text-[18px] text-primary lg:w-[437px]'
              >
                Register. Add your client, business and employees. Start keeping
                track of your businesses
              </Text> */}
              {/* <MailchimpEmailInput
                background='bg-primary'
                buttonVariant='secondary'
                classname=''
              /> */}
              <Button variant='primary' onclick={() => setMainModalOpen(true)}>
                Get early access
              </Button>
            </div>
            <div className='lg:absolute bottom-0 lg:w-[43%] lg:h-[387px]'>
              <img
                src='/images/vector-img.jpg'
                alt=''
                className='w-full h-full object-contain'
              />
            </div>
          </div>
        </Section>
      </div>
      <Footer />

      <Modal
        isOpen={isMainModalOpen}
        classname='flex items-center justify-center '
      >
        <HubspotForm
          onClose={() => setMainModalOpen(false)}
          classname='bg-[#10214F] md:w-[500px] lg:w-[600px]'
        />
      </Modal>
    </React.Fragment>
  );
}
