/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";

export default function Footer({ classname, children }) {
  const classes = clx(
    "bg-primary h-fit py-[50px] flex justify-center",
    classname
  );
  return (
    <div className={classes}>
      <div className='w-[367px] flex flex-col gap-8'>
        <div className='w-fit mx-auto'>
          <img src='/images/footer-logo.png' alt='' />
        </div>
        <div className='flex items-center gap-6 w-fit mx-auto'>
          <div>
            <a
              href='https://www.linkedin.com/company/spectra-risk-solutions/'
              target='_blank'
            >
              <img src='/images/linkedin.png' alt='' />
            </a>
          </div>
          <div>
            <a
              href='https://twitter.com/spectrarisk?s=11&t=VxKL9ZrKDPG_AcJ8eo-c7Q'
              target='_blank'
            >
              <img src='/images/twitter.png' alt='' />
            </a>
          </div>
        </div>
        <div className='flex items-center justify-center gap-6'>
          <Text as='span' classname='text-[20px] text-white font-medium'>
            <a href='https://app.termly.io/document/terms-of-use-for-saas/8fed439b-7a63-42c4-a40a-21a1532cb9b5' target='_blank'>
              Terms and conditions
            </a>
          </Text>
          <Text as='span' classname='text-[20px] text-white font-medium'>
            <a href='https://app.termly.io/document/privacy-policy/78f6e7a7-0f98-4b5c-9491-3b569f0f082b' target='_blank'>
              Privacy policy
            </a>
          </Text>
        </div>
        <Text as='p' classname='text-white text-center'>
          FXIQ is wholly owned by Spectra Risk Solutions LTD, a UK registered
          company
        </Text>
        <Text as='span' classname='text-white text-center'>
          &copy; 2022 FXIQ All rights reserved
        </Text>
        
      </div>
    </div>
  );
}
