export const tabs = [
  {
    id: 1,
    label: "Clients",
    title: "Seamless Onboarding",
    content: [
      "Quick input and verification of your potential clients",
      "Capture and manage your client’s transactions",
      "Clear view of client and transaction risks.",
    ],
    img_route: "/images/client-board.jpg",
  },
  {
    id: 2,
    label: "Employee",
    title: "Activity Management",
    content: [
      "Signup your employees and perform KYC",
      "Track employee's FX deals and calculate their quota",
      "Run standard employee reports for quick insight",
    ],
    img_route: "/images/employee.png",
  },
  {
    id: 3,
    label: "Business",
    title: "Business Management",
    content: [
      "Add all your internal businesses to the platform",
      "Consolidated transaction view across all internal businesses",
      "Bespoke & standard business reports",
    ],
    img_route: "/images/business.png",
  },
  {
    id: 4,
    label: "Transaction",
    title: "360° FX Transaction",
    content: [
      "Capture all FX transaction types",
      "View the risk rating of every transaction",
      "Reconcile your FX deal to Bank transactions",
    ],
    img_route: "/images/transact.jpg",
  },
  {
    id: 5,
    label: "Bank Account",
    title: "Automatic Downloads",
    content: [
      "Integrate your accounts for automatic balances & transactions updates",
      "Reconcile your FX deals with related bank transactions",
      "Categorise your bank transactions for easy reporting",
    ],
    img_route: "/images/bank-account.png",
  },
  {
    id: 6,
    label: "Universal Data",
    title: "Unique Preferences",
    content: [
      "Set up your currency sale logic, e.g. FIFO or aggregated",
      "Set up your currency pairing",
      "Set up your company's bespoke XE rates for platform global use",
    ],
    img_route: "/images/data.jpg",
  },
];
