import React from 'react'
import { clx } from '../utils/clx'

export default function Section({background, classname, children }) {
      const classes = clx(
            "py-10 w-[90%] mx-auto",
            classname
      )
      return (
        <div className={background}>
          <div className={classes}>{children}</div>
        </div>
      );
}