import React from "react";
import { clx } from "../utils/clx";
import Text from "./text";
import { RiArrowDropDownLine } from "react-icons/ri";

export default function Input({
  errorMessage,
  onchange,
  usePhoneStructure,
  useErrorType2,
  value,
  required,
  pattern,
  type,
  label,
  countries,
  countryCode,
  setCountryCode,
  name,
  onblur,
  placeholder,
  classname,
  children,
}) {
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [filteredCountries, setFilteredCountries] = React.useState([]);
  const dropdownRef = React.useRef();

  const formattedCountryCode = (countryCode = '') => {
    if(countryCode.startsWith('+') && countryCode.includes('and')){
      return countryCode.split('and')[0].trim()
    } else {
      if (countryCode.startsWith('+')) {
        return countryCode
      } else {
        return `+${countryCode}`
      }
    }
  }

  React.useEffect(() => {
    if (countries) {
      if (!search) {
        return setFilteredCountries(countries);
      }
      let filtredResult = countries.filter(
        (country) =>
          (`+${(country.phonecode)}`.includes(search) || 
            country.name.toLowerCase().includes(search.toLowerCase())) &&
          country
      );
      return setFilteredCountries(filtredResult);
    }
  });

  React.useEffect(() => {
    document.addEventListener("mouseup", ({ target, currentTarget }) => {
      if (!dropdownRef.current?.contains(target)) {
        setDropdownOpen(false);
      }
    });
    console.log("Phone code", countryCode);
  });

  const classes = clx("bg-[#E2E7F5] lg:p-3 px-3 rounded-[10px]", classname);

  return (
    <div>
      <label htmlFor={name} className='flex flex-col-reverse lg:flex-row space-y-1 lg:space-y-0 text-white'>
        <Text as='span' classname='text-[#E2E7F5]'>
          {label}
          {required && (
            <Text as='span' classname='text-red-500 px-2'>
              *
            </Text>
          )}
        </Text>

        {useErrorType2 && errorMessage && (
          <Text as='span' classname='text-red-500 lg:px-6'>
            ({errorMessage})
          </Text>
        )}
      </label>
      <div className='flex items-center space-x-3'>
        {usePhoneStructure && (
          <div
            className={`${classes} relative py-3 w-fit max-w-[200px] px-3 rounded-[10px] flex items-center space-x-3`}
          >
            {/* Dropdown */}
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className='h-fit absolute inset-0 bg-white rounded-[10px] top-[105%] overflow-x-hidden border-2'
              >
                <input
                  placeholder='Country Code'
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                  className='border-b-2 w-full outline-none py-2 px-3 font-medium font-montserrat placeholder:text-[13px]'
                />
                <div className='mt-2 h-fit max-h-[150px] lg:max-h-[120px] overflow-y-auto w-full flex flex-col space-y-2'>
                  {filteredCountries.map((country) => (
                    <Text
                      key={country.name}
                      as='p'
                      classname='p-3 hover:bg-primary hover:text-white text-[13px] text-base font-semibold cursor-pointer'
                      onclick={({ target, currentTarget }) => {
                        setCountryCode(formattedCountryCode(country.phonecode));
                        setDropdownOpen(false);
                        setSearch("");
                      }}
                    >
                      {formattedCountryCode(country.phonecode)}
                    </Text>
                  ))}
                </div>
              </div>
            )}

            <Text as='p' classname='font-semibold whitespace-nowrap truncate'>
              {countryCode}
            </Text>
            <span
              onClick={() => setDropdownOpen(true)}
              className='cursor-pointer'
            >
              <RiArrowDropDownLine className='text-[24px]' />
            </span>
          </div>
        )}
        <div className={`${classes} w-full`}>
          <input
            type={type}
            pattern={pattern}
            name={name}
            id={name}
            value={value}
            onChange={onchange}
            onBlur={onblur}
            placeholder={placeholder}
            className='w-full lg:px-3 py-1 my-2 lg:my-0 lg:py-0 border-b-[1px] lg:border-b-0 flex-1 bg-transparent focus:outline-none text-base font-montserrat font-semibold placeholder:font-medium'
          />
        </div>
      </div>
      {!useErrorType2 && (
        <Text as='p' classname='text-red-500'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
}
