import React from 'react'
import { clx } from '../utils/clx'

export default function Button({type, onclick, variant='primary', classname, children}) {
      const classes = clx(
            "font-montserrat font-semibold py-3 px-10 rounded-xl text-center cursor-pointer w-fit duration-500 text-[18px]",
            variant === 'primary' && 'bg-primary hover:bg-primary/80 text-secondary',
            variant=== 'secondary' && 'bg-secondary hover:bg-secondary/80 text-primary',
            classname
      )
      return (
            <button type={type} onClick={onclick} className={classes}>
                  {children}
            </button>
      )
}