import axios from "axios";

const portalId = 26005029;
const formGuid = "c34d2ca1-4c38-41f9-b6f7-9f5dec5d7361";
const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
export const submitHubspotForm = async ({ fullname, email, phone }) =>
  axios.post(
    endpoint,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: "full_name",
          value: fullname,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "phone_number",
          value: phone,
        },
      ],
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
