import React from 'react'
import { clx } from '../utils/clx'
import Text from './text'

export default function StepCard({
      step,
      title,
      classname,
      children
}){
      const classes = clx(
            "flex flex-col gap-6 w-[250px] lg:w-[274px] text-center p-4",
            classname
      )
      return (
        <div className={classes}>
          <Text as='p' classname='text-[#B1B7C8] font-extrabold text-[20px]'>
            Step {step}
          </Text>
          <Text as='p' classname='text-primary font-extrabold text-[28px]'>
            {title}
          </Text>
          <Text as='p' classname='text-primary font-semibold text-[13px] lg:text-[18px]'>
            {children}
          </Text>
        </div>
      );
}