import React from "react";
import ReactDOM from "react-dom";
import { clx } from "../utils/clx";

export default function Modal({ isOpen, classname, children }) {
  const classes = clx(
    "bg-black/70 fixed inset-0 w-full h-screen p-6",
    isOpen ? "" : "hidden",
    classname
  );
  return ReactDOM.createPortal(
    <div className={classes}>{children}</div>,
    document.getElementById("modal")
  );
}
