import { Country } from "country-state-city";
import { useFormik } from "formik";
import React from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import { clx } from "../../utils/clx";
import * as Yup from "yup";
import { submitHubspotForm } from "../../utils/submit-hubspot-form";
import { MdCancel } from "react-icons/md";
import Text from "../../components/text";

export default function HubspotForm({
  classname,
  onClose,
  onclick,
  useErrorType2,
  children,
}) {
  const [countries, setCountries] = React.useState([]);
  const [countryCode, setCountryCode] = React.useState("+234");
  const [validationAttempt, setValidationAttempt] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState({message:'',status:'success'});

  const formValues = {
    fullname: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object({
    fullname: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email address is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    let formattedValues = values;
    if (values.phone) {
      formattedValues = {
        ...values,
        phone: `${countryCode}${values?.phone}`,
      };
    }
    console.log("values", formattedValues);
    setSuccessMessage({status:'success', message:"Sending..."});

    submitHubspotForm(formattedValues)
      .then((res) => {
        console.log(res);
        setSuccessMessage({status:'success', message:"Successful!"});
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        if ((err.message = "Network Error")) {
          setSuccessMessage({message:"Oops! Connection not extablished", status:'error'});
        }
          setSuccessMessage({
            message: "Oops!. Something went wrong",
            status: "error",
          });
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: validationAttempt,
  });

  React.useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const classes = clx(
    " mx-auto rounded-[12px] p-6 z-[999] relative",
    classname
  );

  return (
    <div className={classes} onClick={onclick}>
      {/* Cancel Button */}
      <span
        className='absolute top-[15px] right-[10px] z-[40] cursor-pointer w-fit h-fit block'
        onClick={onClose}
      >
        <MdCancel className='text-white text-[24px]'/>
      </span>
      {successMessage.message && (
        <Text
          as='p'
          classname={`${
            successMessage.status !== "success"
              ? "text-red-500"
              : "text-green-500"
          } py-2 font-medium`}
        >
          {successMessage.message}
        </Text>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col space-y-4 lg:space-y-6 z-[999]'>
          <Input
            label='Full Name'
            type='text'
            name='fullname'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            value={formik.values.fullname}
            errorMessage={formik.errors.fullname}
            placeholder='Enter your full name'
            useErrorType2={useErrorType2}
            required
          />
          <Input
            label='Email Address'
            type='email'
            name='email'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            value={formik.values.email}
            errorMessage={formik.errors.email}
            placeholder='Enter your email address'
            useErrorType2={useErrorType2}
            required
          />
          <Input
            label='Phone Number'
            type='number'
            pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
            name='phone'
            onchange={formik.handleChange}
            onblur={formik.handleBlur}
            value={formik.values.phone}
            placeholder='Enter your phone number'
            countries={countries}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            usePhoneStructure
            useErrorType2={useErrorType2}
          />
          <div className='mx-auto w-full md:w-fit pt-4'>
            <Button
              type='submit'
              onclick={() => setValidationAttempt(true)}
              variant='secondary'
              classname='w-full md:w-fit'
            >
              Get early access!
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

// export function Input
